<template>
    <Modal v-model="showForm" title="" class-name="login_modal vertical-center-modal" :width="340"
           :mask-closable="false">
        <div class="login_wrapper">
            <div class="login_text">修改密码</div>
            <div class="login_form">
                <Form ref="editPwdForm" :model="editPwdForm" :rules="editPwdFormRule">
                    <FormItem prop="oldPwd">
                        <Input type="password" size="large" placeholder="原密码" v-model.trim="editPwdForm.oldPwd"/>
                    </FormItem>
                    <FormItem prop="newPwd">
                        <Input type="password" size="large" placeholder="新密码" v-model.trim="editPwdForm.newPwd"/>
                    </FormItem>
                    <FormItem prop="confirmPwd">
                        <Input type="password" size="large" placeholder="确认新密码" v-model.trim="editPwdForm.confirmPwd"/>
                    </FormItem>
                </Form>
            </div>
        </div>
        <div slot="footer">
            <Button type="default" @click="showForm=false">取消</Button>
            <Button type="primary" @click="handleSubmit" :loading="saveLoading">确认</Button>
        </div>
    </Modal>
</template>

<script>
import {updateApplicantPwd} from "../../api/client-api";
import {encode64} from "../../assets/js/utils";

export default {
    name: 'editPassword',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showForm: false,
            saveLoading: false,
            editPwdForm: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: '',
            },
            editPwdFormRule: {
                oldPwd: [{required: true, message: '请输入原密码', trigger: 'blur'}],
                newPwd: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                confirmPwd: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请再次输入新密码'));
                        }
                        let newPwd = (!this.editPwdForm.newPwd || this.editPwdForm.newPwd == '') ? null : this.editPwdForm.newPwd;
                        let confirmPwd = (!this.editPwdForm.confirmPwd || this.editPwdForm.confirmPwd == '') ? null : this.editPwdForm.confirmPwd;
                        if (newPwd != null && newPwd !== confirmPwd) {
                            callback(new Error('两次密码不一致！'));
                        } else {
                            callback();
                        }
                    }
                }]
            }
        }
    },
    methods: {
        //修改密码
        handleSubmit() {
            let that = this;
            that.$refs['editPwdForm'].validate((valid) => {
                if (!valid) {
                    return false;
                }

                that.saveLoading = true;
                let oldPwd = encode64(encode64(that.editPwdForm.oldPwd) + 'oldPwd');
                let newPwd = encode64(encode64(that.editPwdForm.newPwd) + 'newPwd');
                let confirmPwd = encode64(encode64(that.editPwdForm.confirmPwd) + 'confirmPwd');
                updateApplicantPwd({oldPwd: oldPwd, newPwd: newPwd, confirmPwd: confirmPwd}).then((res) => {
                    that.saveLoading = false;
                    if (res.data.data === 1) {
                        that.$Message.success('密码修改成功');
                        sessionStorage.removeItem('un');
                        sessionStorage.removeItem('Authorization');
                        that.$emit('goProjectHome');
                        that.closeModal(false);
                    } else if (res.data.data === 2) {
                        that.$Message.error('新密码不能与原密码相同');
                    } else if (res.data.data === 3) {
                        that.$Message.error('两次输入的新密码不同');
                    } else if (res.data.data === 4) {
                        that.$Message.error('登录已失效');
                    } else if (res.data.data === 5) {
                        that.$Message.error('获取用户信息失败');
                    } else if (res.data.data === 6) {
                        that.$Message.error('原始密码输入不正确');
                    } else {
                        that.$Message.error('密码修改失败');
                    }
                }).catch((err) => {
                    that.saveLoading = false;
                    that.$Message.error('密码修改失败');
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
            } else {
                this.$refs['editPwdForm'].resetFields();
                // 反之则关闭页面
                this.closeModal(val)
            }
        }
    }
}
</script>
