<template>
    <div>
        <div class="header">
            <Row>
                <Col span="18" class="title">
                    <a @click="goProjectHome" v-if="title != null && title != ''"><Icon type="md-home" />{{title}}</a>
                    <div v-else><Icon type="md-home"/>V聘360</div>
                </Col>
                <Col span="6" class="menu" v-if="isLogon">
                    <Dropdown trigger="click" placement="bottom-end">
                        您好，{{username}}
                        <a href="javascript:void(0)">
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem @click.native="handleEditPassword">修改密码</DropdownItem>
                            <DropdownItem @click.native="goMyApplication">我的报名</DropdownItem>
                            <DropdownItem @click.native="logout">退出系统</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <span class="menu_divider">|</span>
                    <Badge :text="messageCounts">
                       <a class="message_badge" @click="goMessageCenter">消息</a>
                    </Badge>
                    <span class="menu_divider">|</span>
                    <a @click="goHelpCenter">帮助</a>
                </Col>
                <Col span="6" class="menu" v-else>
                    <a @click="handleLogin">登录</a> ｜ <a @click="handleRegister">注册</a> ｜ <a @click="goHelpCenter">帮助</a>
                </Col>
            </Row>
        </div>
        <div class="banner">
            <img :src="serverHost + '/resource/public/' + banner" v-if="banner != '' && banner != null ">
            <img src="../../assets/img/default-banner.png" alt="" v-else>
        </div>
        <Login v-model="showLoginModal" :code="projectCode"></Login>
        <EditPassword v-model="showEditPasswordModal" v-on:goProjectHome="goProjectHome"></EditPassword>
    </div>
</template>
<script>
import {getCountOfUnreadMessage} from "../../api/client-api";
import Login from "../../views/pc/login";
import EditPassword from "../../views/pc/editPassword";
import {SERVER_HOST} from '@/common/portConfig';

export default {
    props: {
        code: '',
        title: '',
        banner: null
    },
    components: {Login, EditPassword},
    data() {
        return {
            projectCode:'',
            showLoginModal: false,
            showEditPasswordModal: false,
            serverHost: SERVER_HOST,
            isLogon: false,
            messageCounts: '',
            _timer: null,
            username: ''
        }
    },
    methods: {
        goProjectHome() {
            if (window.location.href.includes('/project/' + this.code)) {
                location.reload();
            } else {
                this.$router.push({name: 'project', params: {id: this.code}});
            }
        },
        goMessageCenter() {
            this.$router.push({name: 'messageCenter', query: {projectCode: this.code}});
        },
        handleRegister() {
            this.$router.push({name: 'register', query: {projectCode: this.code}});
        },
        handleLogin() {
            this.projectCode = this.code
            this.showLoginModal = true;
        },
        handleEditPassword() {
            this.showEditPasswordModal = true;
        },
        goMyApplication() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.code}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.code}});
        },
        countOfUnreadMessage() {
            getCountOfUnreadMessage().then((res) => {
                this.messageCounts = res.data.data + '';
            }).catch((err)=>{
                this.messageCounts = null;
            });
        },
        //退出
        logout() {

            this.$Modal.confirm({
                title: ' ',
                content: '确认退出系统?',
                width: '240',
                onOk: () => {
                    sessionStorage.removeItem('un');
                    sessionStorage.removeItem('Authorization');
                    if (this.code==='' || this.code === null){
                        this.$router.push({name: 'login', query: {projectCode: this.projectCode}})
                    }else{
                        this.goProjectHome();
                    }
                }
            });
        }
    },
    mounted() {
        let that = this;
        that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
        that.username = sessionStorage.getItem('un') || '游客';
        if (that.isLogon) {
            that.countOfUnreadMessage();
            that._timer = setInterval(function() {
                that.countOfUnreadMessage();
            }, 1000*60);
        }
    },
    beforeDestroy() {
        clearInterval(this._timer);
    }
}
</script>
