<template>
    <div class="wrapper">
        <Header :code="projectCode" :title="title" :banner="banner"></Header>
        <div class="content">
            <Tabs type="card" :animated="false" :value="currentTab">
                <TabPane label="招聘公告" name="announcement">
                    <div class="basic-info">
                        <div class="headline">基本信息</div>
                        <div class="section">
                            <Row :gutter="16" align="middle">
                                <Col class="company-info" span="20">
                                    <Form class="plain-form" ref="viewForm" label-colon :label-width="78">
                                        <FormItem label="单位名称">
                                            <div>{{ viewForm.companyName }}</div>
                                        </FormItem>
                                        <FormItem label="招聘时间">
                                            <div>{{ viewForm.startTime }} 至 {{ viewForm.endTime }}</div>
                                        </FormItem>
                                        <FormItem label="所在城市">
                                            <div>{{ viewForm.cityName }}</div>
                                        </FormItem>
                                    </Form>
                                </Col>
                                <Col class="company-logo" span="4" v-if="viewForm.logo != '' && viewForm.logo != null ">
                                    <img :src="serverHost + '/resource/public/' + viewForm.logo">
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div class="announcement">
                        <div class="headline">招聘公告</div>
                        <div class="section" v-html="viewForm.announcement">
                            <div class="multiline-content"></div>
                        </div>
                    </div>
                </TabPane>
                <TabPane label="注意事项" name="notice">
                    <div class="attention">
                        <div class="section" v-html="viewForm.notice">
                            <div class="multiline-content"></div>
                        </div>
                    </div>
                </TabPane>
                <TabPane label="招聘职位" name="position">
                    <div class="job-list">
                        <div class="headline">职位列表<span class="extra-info">共 {{ positionList.length }} 个职位</span></div>
                        <div class="section" v-for="item in positionList" v-if="positionList!=null ">
                            <div class="job-block">
                                <Row :gutter="16" align="middle">
                                    <Col class="job-info" span="20" @click.native="goJobDetail(item.code)">
                                        <div class="job-name">{{ item.name }}<span class="salary">{{
                                                item.salaryFrom === -1 ? '面议' : (item.salaryFrom + 'K - ' + item.salaryTo + 'K')
                                            }}</span></div>
                                        <div class="job-location">{{ item.cityName }}</div>
                                        <div class="job-keywords">
                                            <Tag>{{ item.experienceName }}</Tag>
                                            <Tag>{{ item.highestDegreeName }}</Tag>
                                        </div>
                                    </Col>
                                    <Col class="apply-btn" span="4">
                                        <Button type="primary" size="large" @click="handleApplication(item)">去报名
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
        <Footer></Footer>
        <Login v-model="showLoginModal" :code="code"></Login>
    </div>
</template>

<script>
import {getPositionByProjectCode, getProjectByCode} from "@/api/client-api";
import Header from "../../components/pc/Header";
import Footer from "../../components/pc/Footer";
import Login from "./login";
import {getSendNumberByPhone} from "../../api/client-api";
import {SERVER_HOST} from '@/common/portConfig';

export default {
    components: {Header, Footer, Login},
    data() {
        return {
            banner: null,
            title: null,
            phone: '',
            code: '',
            showLoginModal: false,
            serverHost: SERVER_HOST,
            projectCode: null,
            currentTab: 'announcement',
            viewForm: {
                companyName: '',
                name: '',
                banner: '',
                startTime: null,
                endTime: null,
                cityName: '',
                logo: '',
                announcement: '',
                notice: ''
            },
            positionList: [],
        }
    },
    methods: {
        handleApplication(item) {
            if (sessionStorage.getItem('Authorization')) {
                getSendNumberByPhone({phone: this.phone}).then((res) => {
                    if (res.data.code === 1) {
                        let counts = 0
                        let list = []
                        for (let i = 0; i < res.data.data.length; i++) {
                            list.push(res.data.data[i].code)
                            res.data.data[i].counts = res.data.data[i].counts - 0
                            if (res.data.data[i].projectCode === this.projectCode) {
                                counts = counts + res.data.data[i].counts
                            }
                        }
                        if (counts >= this.viewForm.remark && this.viewForm.remark > 0) {
                            this.$Message.warning("已达到本项目最多投递数量")
                        } else if (list.includes(item.code)) {
                            this.$Message.warning("您已投递过该职位")
                        } else {
                            this.$router.push({name: 'application', query: {projectCode: item.projectCode, positionCode: item.code}});
                        }
                    }
                }).catch(() => {
                    console.log("err")
                })

            } else {
                this.code = this.projectCode
                this.showLoginModal = true;
            }
        },
        getProject() {
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: true,
                loader: 'bars',
                width: 48,
                height: 48,
                color:'#00BBBD',
            })
            getProjectByCode({code: this.projectCode}).then((res) => {
                loader.hide();
                if (res.data.data) {
                    this.viewForm = res.data.data
                    if (this.projectCode === null || this.projectCode === '') {
                        this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                    } else if (this.viewForm.status !== 1){
                        this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                    }
                    this.title = this.viewForm.companyName + this.viewForm.name
                    this.banner = this.viewForm.banner
                }else{
                    this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                }
            }).catch((err) => {
                console.log('err===', err)
            })

            getPositionByProjectCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.positionList = res.data.data;
                }
            }).catch(() => {
                this.positionList = [];
            })

        },
        goJobDetail(code) {
            this.$router.push({name: 'position', params: {id: code}});
        }
    },
    watch: {
        '$route.params.id': {
            handler: function (newVal, oldVal) {
                this.currentTab = this.$route.query.tab ? this.$route.query.tab : 'announcement';
                this.phone = sessionStorage.getItem('un')
                if (newVal===undefined){
                    this.$router.push({name: 'projectPrompt', query: {projectCode: ''}});
                }else {
                    this.projectCode = newVal;
                    this.getProject();
                }
            },
            immediate: true
        }
    },
    mounted() {

    },
};
</script>

<style scoped>

</style>
