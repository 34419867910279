<template>
    <div class="wrapper has_tab_bar">
        <div class="header">
            <van-row>
                <van-col class="title" span="20">{{ viewForm.companyName }}{{ viewForm.name }}</van-col>
                <van-col class="help" span="4"><a @click="goHelpCenter"><van-icon name="question-o" /></a></van-col>
            </van-row>
        </div>
        <div class="banner">
            <img :src="serverHost + '/resource/public/' + viewForm.banner" v-if="viewForm.banner != '' && viewForm.banner != null ">
            <img src="../../assets/img/default-banner.png" alt="" v-else>
        </div>
        <div class="content">
            <van-tabs swipeable sticky v-model="currentTab">
                <van-tab title="招聘公告" name="announcement">
                    <div class="basic-info">
                        <h2 class="van-cell-group-title">基本信息</h2>
                        <van-cell-group :border="false">
                            <van-cell title="单位名称" v-model="viewForm.companyName" :border="false" />
                            <van-cell title="招聘时间" v-model="viewForm.startTime +' 至 '+ viewForm.endTime" :border="false" />
                            <van-cell title="所在城市" v-model="viewForm.cityName" :border="false" />
                        </van-cell-group>
                    </div>
                    <div class="announcement">
                        <h2 class="van-cell-group-title">招聘公告</h2>
                        <div class="html-content" v-html="viewForm.announcement">
                        </div>
                    </div>
                </van-tab>
                <van-tab title="注意事项" name="attention">
                    <div class="attention">
                        <div class="html-content" v-html="viewForm.notice">
                        </div>
                    </div>
                </van-tab>
                <van-tab title="招聘职位" name="job-list">
                    <div class="job-list" >
                        <h2 class="van-cell-group-title">职位列表<span class="extra-info">共 {{ positionList.length }} 个职位</span></h2>

                        <div class="job-block"  v-for="item in positionList" v-if="positionList!=null ">
                            <div class="job-info" @click="goJobDetail(item.code)">
                                <div class="job-name">{{ item.name }}<span class="salary">{{item.salaryFrom === -1 ? '面议' : (item.salaryFrom + 'K - ' + item.salaryTo + 'K') }}</span></div>
                                <div class="job-location">{{ item.cityName }}</div>
                                <div class="job-keywords"><van-tag plain>{{ item.experienceName }}</van-tag><van-tag plain>{{ item.highestDegreeName }}</van-tag></div>
                            </div>
                            <div class="apply-btn">
                                <van-button type="primary" size="small"  @click="handleApplication(item)">去报名</van-button>
                            </div>
                        </div>

                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div class="footer">
        </div>

        <div class="tab_bar">
            <van-tabbar v-model="active">
                <van-tabbar-item name="home" icon="wap-home">招聘主页</van-tabbar-item>
                <van-tabbar-item :badge='messageCounts' name="message" icon="chat" @click="goMessageCenter">消息中心</van-tabbar-item>
                <van-tabbar-item name="application" icon="column" @click="goMyApplication">我的报名</van-tabbar-item>
                <van-tabbar-item name="my" icon="manager" @click="goUserCenter">个人中心</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
import {
    getProjectByCode,
    getPositionByProjectCode,
    getSendNumberByPhone,
    getCountOfUnreadMessage
} from "../../api/client-api";
import {SERVER_HOST} from '@/common/portConfig';

export default {
    data() {
        return {
            active: 'home',
            messageCounts: "",
            phone: '',
            code: '',
            showLoginModal: false,
            serverHost: SERVER_HOST,
            projectCode: null,
            currentTab: 'announcement',
            viewForm: {
                companyName: '',
                name: '',
                banner: '',
                startTime: null,
                endTime: null,
                cityName: '',
                logo: '',
                announcement: '',
                notice: ''
            },
            positionList: [],
        }
    },
    methods: {
        getProject() {
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: true,
                loader: 'bars',
                width: 48,
                height: 48,
                color:'#00BBBD',
            })
            getProjectByCode({code: this.projectCode}).then((res) => {
                loader.hide();
                if (res.data.data) {
                    this.viewForm = res.data.data
                    if (this.projectCode === null || this.projectCode === '') {
                        this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                    } else if (this.viewForm.status !== 1){
                        this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                    }
                    this.title = this.viewForm.companyName + this.viewForm.name
                    this.banner = this.viewForm.banner
                }else{
                    this.$router.push({name: 'projectPrompt', query: {projectCode: this.projectCode}});
                }
            }).catch((err) => {
                console.log('err===', err)
            })
            getPositionByProjectCode({code: this.projectCode}).then((res) => {
                if (res.data.data) {
                    this.positionList = res.data.data;
                }
            }).catch(() => {
                this.positionList = [];
            })
        },
        goJobDetail(code) {
            this.$router.push({name: 'position', params: {id: code}});
        },
        goMessageCenter() {
            this.$router.push({name: 'messageCenter', query: {projectCode: this.projectCode}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        goMyApplication() {
            this.$router.push({name: 'myApplication', query: {projectCode: this.projectCode}});
        },
        goUserCenter() {
            this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
        },
        handleApplication(item) {
            if (sessionStorage.getItem('Authorization')) {
                getSendNumberByPhone({phone: this.phone}).then((res) => {
                    if (res.data.code === 1) {
                        let counts = 0
                        let list = []
                        for (let i = 0; i < res.data.data.length; i++) {
                            list.push(res.data.data[i].code)
                            res.data.data[i].counts = res.data.data[i].counts - 0
                            if (res.data.data[i].projectCode === this.projectCode) {
                                counts = counts + res.data.data[i].counts
                            }
                        }
                        if (counts >= this.viewForm.remark && this.viewForm.remark > 0) {
                            this.$notify({type: 'danger', duration: '1000', message: '已达到本项目最多投递数量'});
                        } else if (list.includes(item.code)) {
                            this.$notify({type: 'danger', duration: '1000', message: '您已投递过该职位'});
                        } else {
                            this.$router.push({name: 'application', query: {projectCode: item.projectCode, positionCode: item.code, backTo: 'project'}});
                        }
                    }
                }).catch(() => {
                    console.log("err")
                })
            } else {
                let redirectTo = window.location.href;
                sessionStorage.setItem('redirectTo', redirectTo);
                this.$router.push({name:'login'});
            }
        },
        countOfUnreadMessage() {
            getCountOfUnreadMessage().then((res) => {
                if (res.data.data !== 0){
                    this.messageCounts = res.data.data + '';
                }
            }).catch((err)=>{
                this.messageCounts = null;
            });
        },
    },
    watch: {
        '$route.params.id': {
            handler: function (newVal, oldVal) {
                this.currentTab = this.$route.query.tab ? this.$route.query.tab : 'announcement';
                this.phone = sessionStorage.getItem('un')
                let that = this;
                that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
                if (that.isLogon) {
                    that.countOfUnreadMessage();
                    that._timer = setInterval(function() {
                        that.countOfUnreadMessage();
                    }, 1000*60);
                }
                if (newVal===undefined){
                    this.$router.push({name: 'projectPrompt', query: {projectCode: ''}});
                }else {
                    this.projectCode = newVal;
                    this.getProject();
                }
            },
            immediate: true
        }
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>
